import React from "react";


function VideoGuide() {
    return <>
        <div className="row justify-content-center">
            <h3 className="content-right-upper-heading">Vítejte ve své administraci Ratingo</h3>
        </div>
        <div className="row justify-content-center">
            <strong>Video návod. Jak si vyzkoušet aplikaci jako restaurace i jako host.</strong>
        </div>
        <div className="row justify-content-center">
            <div style={{ padding: "0 0 0 0", position: "relative", width: 600, height: 450}}>
                <iframe
                    src="https://player.vimeo.com/video/767256065?h=8123a1eaea&badge=0&autopause=0&player_id=0&app_id=58479"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen={true}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "600px",
                        height: "500px"
                    }}
                    title="napoveda.mp4"
                />
            </div>
        </div>

        <div className="row justify-content-center" style={{fontSize: "18px"}}>
            <a href="https://www.ratingo.io/karticky" style={{color: "#FF9E3B"}}>Objednat kartičky na stoly</a>
        </div>

    </>;
}

export default VideoGuide;