import {FormEvent, useContext, useEffect, useState} from "react";
import firebase from "firebase";
import {UserContext} from "../context";

function Settings() {
    const [ownerName, setOwnerName] = useState<string>('jméno restauratéra');
    const [reward, setReward] = useState<string>('večeře pro dva');
    const [restaurantName, setRestaurantName] = useState<string>('název restaurace');
    const [address, setAddress] = useState<string>('adresa ...');
    const {effectiveUser} = useContext(UserContext);

    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [logoFile, setLogoFile] = useState<File | null>(null);

    const storageRef = firebase.storage().ref();

    let settings = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid).collection("Settings").doc('settings');

    useEffect(() => {
        settings.onSnapshot(settings => {
            parseDatabase(settings);
            loadImage();
        });
    }, []);

    function saveSettings(e: FormEvent) {
        e.preventDefault();

        logoFile?.arrayBuffer().then( buffer => {
            storageRef.child("RestaurantLogo/" + effectiveUser.uid + ".png").put(buffer).then(() => {
                alert("logo nahráno");
                settings.set({
                    ownerName: ownerName,
                    reward: reward,
                    restaurantName: restaurantName,
                    address: address
                }).then(() => {
                    alert("Nastavení uloženo");
                    loadImage();
                });
            });
        })
    }

    function loadImage() {
        storageRef.child("RestaurantLogo").child( effectiveUser.uid + ".png").getDownloadURL().then( downloadURL => {
            setLogoUrl(downloadURL);
        }, rejectReason => {
            setLogoUrl(null);
        });
    }

    const changeHandler = (event: any) => {
        if (event.target.files[0] && event.target.files[0].size > 1024 * 512) {
            event.target.value = null;
            alert("Obrázek je příliš velký");
        } else {
            setLogoFile(event.target.files[0]);
        }
    };

    function reloadSettings() {
        settings.get().then(snapshot => {
            parseDatabase(snapshot);
        });
    }

    function parseDatabase(settings: any) {
        let data = settings.data() || {};

        if (data.ownerName !== ownerName) {
            setOwnerName(data.ownerName);
        }

        if (data.reward !== reward) {
            setReward(data.reward);
        }

        if (data.restaurantName !== restaurantName) {
            setAddress(data.restaurantName);
        }

        if (data.address !== address) {
            setAddress(data.address);
        }
    }

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Nastavení</h3>
            <div>
                <button type="button" className="get-qr gray" onClick={e => {e.preventDefault(); reloadSettings()}}>Neukládat</button>
                &nbsp;&nbsp;
                <button type="button" className="get-qr gastro" onClick={saveSettings}>Uložit</button>
            </div>
        </div>

        <div className="input-container">
            <div className="question">
                <div className="qr-box-right" >
                    {logoUrl ? <img src={logoUrl} alt="" style={{maxWidth: '150px'}} /> : "Nahrajte prosím logo"}
                    <br />
                    <p>Podporovaný formát: PNG<br />doporuřené rozměry: 200x200 - 600x600<br />Maximální velikost: 500kB</p>
                    <input type="file" name="file" accept="image/png,image/x-png,image/vnd.mozilla.apng" onChange={e => changeHandler(e)} />
                    <br />
                    <br />

                    <label htmlFor="ownerName">Jméno Restauratéra:</label>
                    <input className="form-control gastro" name="ownerName" type="text" value={ownerName} onChange={e => setOwnerName(e.target.value)} />

                    <label htmlFor="reward">Odměna:</label>
                    <input className="form-control gastro" name="reward" type="text" value={reward} onChange={e => setReward(e.target.value)} />

                    <label htmlFor="reward">Název restaurace:</label>
                    <input className="form-control gastro" name="restaurantName" type="text" value={restaurantName} onChange={e => setRestaurantName(e.target.value)} />

                    <label htmlFor="reward">Adresa:</label>
                    <textarea className="form-control gastro" rows={6} name="address" value={address} onChange={e => setAddress(e.target.value)} />
                </div>
            </div>
        </div>
    </>
}

export default Settings;