import {useContext, useEffect, useState} from "react";
import firebase from "firebase";
import QRCode from "qrcode.react";
import {UserContext} from "../context";

declare class ClipboardItem {
    constructor(data: { [mimeType: string]: Blob });
}

function RestaurantQrCode() {

    const {effectiveUser} = useContext(UserContext);

    function qrCodeURL() {
        return "https://gastro.ratingo.io/Clip?userId=" + effectiveUser.uid;
    }

    function getQrCodeImgSrc(): string {
        var canvas = document.getElementById("qrcode-canvas") as any;
        if (!canvas) {
            return "";
        }

        return canvas.toDataURL("image/png");
    }

    function copyQrCode() {
        const canvas = document.getElementById("qrcode-canvas") as HTMLCanvasElement;
        canvas.toBlob(function(blob) {
            const item = new ClipboardItem({ "image/png": blob || new Blob() });
            (navigator.clipboard as any).write([item]);
        });
    }

    return <>

            <h3 className="content-right-upper-heading">QR kód pro otevření dotazníku</h3>
            <p></p>
            <p></p>

            <div className="qr-img" style={{width: "280px", textAlign: "center", padding: "30px"}}>
                <QRCode id={"qrcode-canvas"} size={200} value={qrCodeURL()} />
                <p></p>

                <button className="get-qr gastro" onClick={e => {e.preventDefault(); copyQrCode();}}><img src="/img/copy-qr.png" alt="" />Kopírovat QR kód do
                    schránky</button>
            </div>



        <p></p>

    </>
}

export default RestaurantQrCode;