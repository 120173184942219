import {FormEvent, useContext, useEffect, useState} from "react";
import firebase from "firebase";
import QRCode, {displayName} from "qrcode.react";
import  { AppContext } from '../context';

interface Props {
    selectSection: (section: string) => void;
}

interface QrCode {
    id: string
    question: string
    benefit: string
    b2c: boolean
    orientation: string
    order: number
}

declare class ClipboardItem {
    constructor(data: { [mimeType: string]: Blob });
}

function sortedQrArraysEqual(array1: QrCode[], array2: QrCode[]) {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i].question !== array2[i].question ||
            array1[i].orientation !== array2[i].orientation) {
            return false;
        }
    }

    return true;
}

function QrCodes(props: Props) {
    const [qrCodes, setQrCodes] = useState<QrCode[]>([]);

    const [newQuestion, setNewQuestion] = useState<string>("");
    const [newBenefit, setNewBenefit] = useState<string>("");
    const [displayQRCodeImg, setDisplayQRCodeImg] = useState<boolean>(false);

    const { isB2c } = useContext(AppContext);

    let qrCodesCollection = firebase.firestore().collection("UserData").doc(firebase.auth().currentUser?.uid)
        .collection("QrCodes");

    function addQrCode(e: FormEvent) {
        e.preventDefault();

        let lastOrder = qrCodes.map(code => {return code.order}).sort((a, b) => a - b)[qrCodes.length - 1] || 1;

        qrCodesCollection.add({
            question: newQuestion,
            benefit: newBenefit,
            b2c: isB2c,
            orientation: 'landscape',
            order: lastOrder + 1
        });

        setNewQuestion("");
    }

    function deleteQrCode(id: string) {
        qrCodesCollection.doc(id).delete();
    }

    function copyQrCode(id: string) {
        const canvas = document.getElementById("qrcode-" + id) as HTMLCanvasElement;
        canvas.toBlob(function(blob) {
            const item = new ClipboardItem({ "image/png": blob || new Blob() });
            (navigator.clipboard as any).write([item]);
        });
    }

    function copyURL(qrCode: QrCode) {
        (navigator.clipboard as any).writeText(qrCodeURL(qrCode));
    }

    function copyBoth(qrCode: QrCode) {
        let selection = window.getSelection();
        let container = document.querySelector("#clipboard-" + qrCode.id);

        if (!selection || !container) {
            return;
        }

        if (selection.rangeCount > 0) {
            selection.removeAllRanges();
        }

        const range = document.createRange();
        range.selectNode(container);
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
    }

    function qrCodeURL(qrCode: QrCode) {
        return "https://app.ratingo.io/Landscape?question=" + encodeURI(qrCode.question) +
        "&benefit=" + encodeURI(qrCode.benefit) +
        "&userId=" + firebase.auth().currentUser?.uid +
            (qrCode.b2c ? "&b2c=1" : "");
    }

    function getQrCodeImgSrc(qrCode: QrCode): string {
        var canvas = document.getElementById("qrcode-" + qrCode.id) as any;
        if (!canvas) {
            return "";
        }

        return canvas.toDataURL("image/png");
    }

    useEffect(() => {
        qrCodesCollection.orderBy("order", 'desc').onSnapshot(snapshot => {
            const firebaseQrCodes: QrCode[] = [];

            snapshot.forEach( fbQrCode => {
                let data = fbQrCode.data() || {};

                firebaseQrCodes.push({
                    id: fbQrCode.id,
                    question: data.question,
                    benefit: data.benefit,
                    b2c: data.b2c || false,
                    orientation: data.orientation,
                    order: data.order
                });
            });

            if (!sortedQrArraysEqual(qrCodes, firebaseQrCodes)) {
                setQrCodes(firebaseQrCodes);
            }
        });

        if (qrCodes.length > 0) {
            setDisplayQRCodeImg(true); // this makes sure we render the image after the canvas has been rendered
        }
    });

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">QR kódy</h3>
        </div>
        <div className="input-container">
            <span className="input-container-txt">{isB2c ? "Dostane referent odměnu?" : "Na co se chcete zeptat?"}</span>
            <form onSubmit={addQrCode}>
                <div className="input-container-content">
                    <div className="row">
                        {!isB2c &&
                            <div className="col-lg-4 col-md-6 col-sm-12"><input type="text" placeholder="Zadejte otázku" className="form-control" value={newQuestion} onChange={e => {setNewQuestion(e.target.value)}} /></div>
                        }
                        <div className="col-lg-4 col-md-6 col-sm-12"><input type="text" placeholder="Zadejte benefit (nepovinné)" className="form-control" value={newBenefit} onChange={e => {setNewBenefit(e.target.value)}} /></div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <button className="input-submit-btn">Generovat QR kód</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        {qrCodes.map((qrCode) =>
            <div className="qr-box qr-box-one">
                <div className="qr-box-left">
                    <div className="qr-img"><QRCode id={"qrcode-" + qrCode.id} size={150} value={qrCodeURL(qrCode)} /></div>
                </div>
                <div className="qr-box-right">
                    <div className="qr-box-right-upper-large-device">
                        <h3 className="qr-right-heading">{qrCode.question}</h3>
                        {qrCode.benefit &&
                            <div className="qr-right-tag-container">
                                <span className="benefit-tag">Benefit</span> <span className="benefit-txt">{qrCode.benefit}</span>
                            </div>
                        }
                    </div>
                    <div className="qr-right-buttons">
                        <div style={{position: 'absolute', left: '-1000px', top: '-1000px'}} id={"clipboard-" + qrCode.id}>
                            URL App Clipu: {qrCodeURL(qrCode)}
                            <div className="qr-img">
                                {displayQRCodeImg &&
                                    <img src={getQrCodeImgSrc(qrCode)} alt="QR kód" style={{width: '150px', height: '150px'}}/>
                                }
                            </div>
                            Po nascanování tohoto QR kódu se otevře aplikace Ratingo.
                        </div>
                        <div className="qr-right-btn-main">
                            <button className="copy-qr-code" onClick={e => {e.preventDefault(); copyQrCode(qrCode.id);}}><img src="/img/copy-qr.png" alt="" />Kopírovat QR kód do
                                schránky</button>

                            <button className="copy-qr-code ml-2" onClick={e => {e.preventDefault(); copyURL(qrCode);}}><img src="/img/copy-link.png" alt="" />Kopírovat odkaz do schránky</button>

                            <button className="copy-qr-code ml-2" onClick={e => {e.preventDefault(); copyBoth(qrCode);}}><img src="/img/copy-link.png" alt="" />Kopírovat obojí</button>
                        </div>

                        <button className="trash-btn" onClick={e => {e.preventDefault(); deleteQrCode(qrCode.id);}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <defs>
                                    <style>{`.a{fill:none;}.b{fill:#a6b3bf;}`}</style>
                                </defs>
                                <rect className="a" width="24" height="24" transform="translate(0 0)"/>
                                <path className="b"
                                      d="M5.21,19.507a3.016,3.016,0,0,1-2.993-2.77L1.306,4.876H.75a.75.75,0,0,1-.1-1.493l.1-.006H4.064v-1.5A1.878,1.878,0,0,1,5.94,0h5.627a1.878,1.878,0,0,1,1.876,1.876v1.5h3.315a.75.75,0,0,1,.1,1.493l-.1.006H16.2l-.912,11.861a3.015,3.015,0,0,1-2.993,2.77Zm-1.5-2.885A1.5,1.5,0,0,0,5.073,18l.137.006H12.3a1.508,1.508,0,0,0,1.5-1.386l.9-11.746H2.809ZM5.565,1.876v1.5h6.379v-1.5a.377.377,0,0,0-.3-.368L11.567,1.5H5.94A.376.376,0,0,0,5.565,1.876ZM6.911,13.314a.75.75,0,0,1-.127-.971l.068-.087.843-.942-.942-.843a.75.75,0,0,1,.912-1.185l.088.068.942.842.842-.941a.75.75,0,0,1,1.186.912l-.068.088-.843.942.942.842a.75.75,0,0,1-.913,1.186l-.087-.068-.942-.843-.843.942a.749.749,0,0,1-1.058.059Z"
                                      transform="translate(3.252 2.251)"/>
                            </svg>
                            Smazat QR kód
                        </button>

                    </div>

                </div>
            </div>
        )}
    </>
}

export default QrCodes;