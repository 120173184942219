import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContext } from './context';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB9Pcs0f6gUAS72Pk8fvzavwTwMK9Azavg",
    authDomain: "ratingo-21e9a.firebaseapp.com",
    projectId: "ratingo-21e9a",
    storageBucket: "gs://ratingo-21e9a.appspot.com",
    messagingSenderId: "669504814443",
    appId: "1:669504814443:web:9dd0619a3ded126c44202e",
    measurementId: "G-18S8E278DC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let rootElement = document.getElementById('root')

const isB2c = rootElement.className === 'b2c'
const isRestaurant = rootElement.className === 'restaurant'

ReactDOM.render(
  <React.StrictMode>
      <AppContext.Provider value={{isB2c: isB2c, isRestaurant: isRestaurant}}>
        <App />
      </AppContext.Provider>
  </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
