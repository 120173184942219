import './css/style.css';

import Menu from "./includes/Menu";
import TopBar from "./includes/TopBar";
import {useContext, useEffect, useState} from "react";
import Videos from "./sections/Videos";
import BuyCredits from "./sections/BuyCredits";
import Settings from "./sections/Settings";
import QrCodes from "./sections/QrCodes";
import VideoGuide from "./sections/VideoGuide";
import RestaurantFeedbacks from "./sections/RestaurantFeedbacks";
import {AppContext} from "./context";
import RestaurantQuestions from "./sections/RestaurantQuestions";
import RestaurantQrCode from "./sections/RestaurantQrCode";
import DefineLogo from "./sections/DefineLogo";
import RestaurantEmails from "./sections/ResturantEmails";
import Winners from "./sections/Winners";

function MainApp() {
    const { isB2c, isRestaurant } = useContext(AppContext);

    const [selectedSection, setSelectedSection] = useState(isRestaurant ? "videoGuide" : "videos");

    useEffect(() => {
        let navChecker: HTMLInputElement | null = document.querySelector('.nav-checker');
        let sideNav = document.getElementById('sidenav');

        navChecker?.addEventListener('change', (event) => {
            if(navChecker?.checked === true) {
                sideNav?.classList.add('content-left-small');
            } else {
                sideNav?.classList.remove('content-left-small');
            }
        });
    });

    return <>
        <main className="main-dashboard">
            <div className="container-fluid">
                <TopBar />
                <div className="main-content">
                    <Menu selectedSection={selectedSection} selectSection={setSelectedSection} />
                    <div className="content-right">
                        {selectedSection === 'videos' && <Videos selectSection={setSelectedSection} />}

                        {selectedSection === 'restaurantEmails' && <RestaurantEmails />}

                        {selectedSection === 'restaurantFeedbacks' && <RestaurantFeedbacks />}

                        {selectedSection === 'winners' && <Winners />}

                        {selectedSection === 'restaurantQuestions' && <RestaurantQuestions />}

                        {selectedSection === 'restaurantQrCode' && <RestaurantQrCode />}

                        {selectedSection === 'qrCodes' && <QrCodes selectSection={setSelectedSection} />}

                        {selectedSection === 'videoGuide' && <VideoGuide />}

                        {selectedSection === 'buyCredits' && <BuyCredits />}

                        {selectedSection === 'settings' && <Settings />}

                        {selectedSection === 'defineLogo' && <DefineLogo />}
                    </div>
                </div>
            </div>
        </main>
    </>
}

export default MainApp;
