import firebase from "firebase/app";
import Login from "./Login";
import React, {useEffect, useState} from "react";
import MainApp from "./MainApp";
import {UserContext} from "./context";

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Home from "./clip/home";
import Question from "./clip/question";
import Thanks from "./clip/thanks";
import End from "./clip/end";

function App() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [effectiveUser, setEffectiveUser] = useState(firebase.auth().currentUser);

    const [email, setEmail] = useState(false);
    const [response, setResponse] = useState([]);
    const [agreeMarketing, setAgreeMarketing] = useState(false);
    const [clipSettings, setClipSettings] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [userId, setUserId] = useState(null);

    function getUrlUserId() {
        const windowUrl = window.location.search
        const params = new URLSearchParams(windowUrl)
        return params.get('userId')
    }

    function sendResponse(then) {
        let newResponse = firebase.functions().httpsCallable("newResponse");

        newResponse({
            "userId": userId,
            "responses": response,
            "email": email,
            "agreeMarketing": false,
            "source": "web"
        }).then(() => {
            then();
        });
    }

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            user && setEffectiveUser(user);
            setIsSignedIn(!!user);
        });

        if (getUrlUserId()) {
            setUserId( getUrlUserId() );
            let getQuestions = firebase.functions().httpsCallable("getQuestions");
            let getLogoUrl = firebase.functions().httpsCallable("getLogoUrl");

            getQuestions({
                "userId": getUrlUserId()
            })
                .then((response) => {
                    setClipSettings(response.data);
                });
            // .catch(error => {
            // })

            getLogoUrl({
                "userId": getUrlUserId()
            }).then(response => {
                setLogoUrl(response.data.url);
            });
        }

        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return  <>
        {userId ?
            clipSettings ?
            <BrowserRouter basename={document.getElementById('root').dataset.prefixUrl}>
                <Routes>
                    <Route path='/Clip' element={<Home  logoUrl={logoUrl} questions={clipSettings.questions} reward={clipSettings.settings.reward}/>} />
                    <Route path='/Clip/otazka/:questionId' element={<Question questions={clipSettings.questions} response={response} setResponse={setResponse}  userId={userId}  />} />
                    <Route path='/Clip/dekujeme' element={<Thanks sendResponse={sendResponse} setAgreeMarketing={setAgreeMarketing} settings={clipSettings.settings} email={email} setEmail={setEmail} />} />
                    <Route path='/Clip/konec' element={<End />} />
                </Routes>
            </BrowserRouter>
                : <p>Načítám...</p>
            :
            isSignedIn ?
                <UserContext.Provider value={{effectiveUser: effectiveUser, setEffectiveUser: setEffectiveUser}}>
                    <MainApp/>
                </UserContext.Provider>
                :
                <Login/>
        }
        </>

}

export default App;
