import {useContext, useEffect, useState} from "react";
import firebase from "firebase";
import {UserContext} from "../context";

const months = [
    { dbName: "January", displayName: "Leden" },
    { dbName: "February", displayName: "Únor" },
    { dbName: "March", displayName: "Březen" },
    { dbName: "April", displayName: "Duben" },
    { dbName: "May", displayName: "Květen" },
    { dbName: "June", displayName: "Červen" },
    { dbName: "July", displayName: "Červenec" },
    { dbName: "August", displayName: "Srpen" },
    { dbName: "September", displayName: "Září" },
    { dbName: "October", displayName: "Říjen" },
    { dbName: "November", displayName: "Listopad" },
    { dbName: "December", displayName: "Prosinec" }
]

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function RestaurantQuestions() {
    const {effectiveUser} = useContext(UserContext);

    const [questions, setQuestions] = useState<{[key: string]: string}>({
        'January': "init"
    });

    const forceUpdate = useForceUpdate();

    let questionCollection = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
        .collection("Questions");

    useEffect(() => {
        questionCollection.doc("questions").onSnapshot(snapshot => {
            parseDatabase(snapshot);
        });
    }, []);


    function updateQuestion(month: string, value: string) {
        questions[month] = value;
        setQuestions(questions);
        forceUpdate();
    }

    function reloadQuestions() {
        questionCollection.doc("questions").get().then(snapshot => {
            parseDatabase(snapshot);
        });
    }

    function parseDatabase(snapshot: any) {
        const data = snapshot.data() || {};
        const questionsDb: { [key: string]: string } = {}

        months.forEach(month => {
            questionsDb[month.dbName] = data[month.dbName]
        })

        console.log("read questions");
        setQuestions(questionsDb);
    }

    function saveQuestions() {
        questionCollection.doc("questions").update(questions);
        alert("Otázky uloženy");
    }

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Otázky</h3>
            <div>
                <button type="button" className="get-qr gray" onClick={e => {e.preventDefault(); reloadQuestions()}}>Neukládat</button>
                &nbsp;&nbsp;
                <button type="button" className="get-qr gastro" onClick={e => {e.preventDefault(); saveQuestions()}}>Uložit</button>
            </div>
        </div>
        <p></p>

        {months.map((month) =>
            <div className="question">
                <div className="qr-box-right" >
                    <h3 className="qr-right-heading">{month.displayName}</h3>
                    <label htmlFor="test-select">Inspirace od nás:</label>
                    <select name={"inspiration-" + month.dbName } className="form-control gastro" onChange={e => {updateQuestion(month.dbName, e.target.value)}}>
                        <option disabled selected value=""> -- vyberte -- </option>
                        <option>Odpovídají podle vás naše ceny zážitku, který si odnášíte?</option>
                        <option>Chybí vám něco v našem jídelním lístku?</option>
                        <option>Splňuje naše restaurace váš standard čistoty a hygieny?</option>
                        <option>Jak se cítíte v prostředí naší restaurace? Vyhovuje vám interiér, teplota, osvětlení či hudba?</option>
                        <option>Jak jste se o nás dozvěděli?</option>
                        <option>Doporučili byste nás rodině a přátelům, nebo nikoliv? A proč?</option>
                        <option>Můžeme udělat něco pro to, abyste příště byli spokojenější?</option>
                        <option>Jak rychle vám jídlo po objednání dorazilo?</option>
                        <option>Chybí vám v naší nabídce nějaké nápoje?</option>
                        <option>Jak byste ohodnotili náš personál?</option>
                        <option>Kolikrát měsíčně chodíte do restaurací?</option>
                        <option>Navštěvujete konkurenční podniky? Pokud ano, tak proč? Co se vám u nich líbí?</option>
                        <option>Objednáte si jídlo z restaurace domů? Pokud ano, objednáváte si ho od nás, nebo z jiných podniků? A proč?</option>
                        <option>Navštěvujete naši restauraci pravidelně? A proč?</option>
                        <option>Co bychom mohli pro vás udělat, abyste naši restauraci navštěvovali pravidelně?</option>
                        <option>Přáli byste si, aby naše restaurace byla více baby friendly (dětský koutek, více dětských jídelních židliček, jídel pro děti apod.)?</option>
                        <option>Je něco, co můžeme udělat, abyste nás navštěvovali častěji?</option>
                        <option>Máte námět na zlepšení, jak vyřešit kuřácké prostory?</option>
                        <option>Jste spokojeni s parkováním před restaurací?</option>
                        <option>Vyhovuje vám naše zahrádka? Máte nějaký námět ke zlepšení?</option>
                        <option>Ocenili byste, kdybychom vám nabídli zábavné akce (např. živou hudbu, tematické večery, degustace apod.)?</option>
                        <option>Líbilo by se Vám v našem podniku zorganizovat svatební hostinu, soukromou oslavu nebo firemní večírek?</option>
                        <option>Navštěvovali byste vyhřívanou zimní zahrádku, pokud bychom ji měli?</option>
                    </select>
                    <label htmlFor="test-select">Otázka:</label>
                    <input className="form-control gastro" name={"question-" + month.dbName } type="text" value={questions[month.dbName]} onChange={e => {updateQuestion(month.dbName, e.target.value)}}/>
                </div>
            </div>
        )}
    </>
}

export default RestaurantQuestions;