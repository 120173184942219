import firebase from "firebase/app";
import React, {useEffect, useState, MouseEvent, useContext} from "react";
import 'firebase/storage';
import {UserContext} from "../context";

function sortedWinnerArraysEqual(array1: Winner[], array2: Winner[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (array1[i].code !== array2[i].code) {
            return false;
        }
    }

    return true;
}

interface Winner {
    date: Date,
    email: string
    code: string
}

function Winners() {

    const [winners, setWinners] = useState<Winner[]>([]);

    const {effectiveUser} = useContext(UserContext);

    useEffect(() => {
        let winnersCollection = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Winners");

        winnersCollection.orderBy("date", "desc")
            .onSnapshot(snapshot => {
                const allWinners: Winner[] = [];

                snapshot.forEach(dbWinner => {
                    let data = dbWinner.data() || {};

                    allWinners.push({
                        date: new Date(data.date.seconds * 1000),
                        email: data.email,
                        code: data.code
                    });
                });

                if (!sortedWinnerArraysEqual(allWinners, winners)) {
                    setWinners(allWinners);
                }
            });
    });

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Výherci</h3>
        </div>

        {winners.length === 0 ?
            <div className="row justify-content-center">
                <h1>Zatím nikdo nevyhrál.</h1>
            </div>
            :
            <div id="all-winners" className="row">
                <div className="col">
                    <table className="feedback-table">
                        <tr>
                            <th>Datum</th>
                            {(firebase.auth().currentUser?.email === 'tonda.karasek@gmail.com' || firebase.auth().currentUser?.email === 'petra@smartonline.cz') &&
                                <th>email</th>
                            }
                            <th>kód</th>
                        </tr>
                        {winners.map((winner) =>
                            <tr>
                                <td style={{width: '25%', paddingRight: '30px', paddingBottom: '30px'}}>
                                    <strong>{Intl.DateTimeFormat('cs-CZ').format(winner.date)}</strong>
                                </td>
                                {(firebase.auth().currentUser?.email === 'tonda.karasek@gmail.com' || firebase.auth().currentUser?.email === 'petra@smartonline.cz') &&
                                    <td>
                                        {winner.email}
                                    </td>
                                }
                                <td>
                                    {winner.code}
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
            </div>
        }
    </>
}

export default Winners;