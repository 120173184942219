import './css/style.css';
import firebase from "firebase/app";
import {useContext, useState} from "react";
import {AppContext} from "./context";

function Login() {

    const { isB2c, isRestaurant } = useContext(AppContext);

    const [emailLogin, setEmailLogin] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [restaurantName, setRestaurantName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [emailEntered, setEmailEntered] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string>("");
    const [register, setRegister] = useState<boolean>(false);
    const [registered, setRegistered] = useState<boolean>(false);
    const [registering, setRegistering] = useState<boolean>(false);


    function loginClicked() {
        setLoginError("");

        if (emailEntered) {
            firebase.auth().signInWithEmailAndPassword(email.trim(), password)
                .catch(error => {
                    if (error.code === 'auth/wrong-password') {
                        setLoginError("Chybné jméno nebo heslo.");
                    } else {
                        setLoginError("Chyba: " + error.message);
                    }
                });
        } else {
            setEmailEntered(true);
        }
    }

    function createAccount() {
        if (!checkEmail(email)) {
            setLoginError("Chybný formát emailové adresy");
            return;
        }

        setLoginError("");
        setRegistering(true)

        let createAccount = firebase.functions().httpsCallable("createAccount");
        createAccount({
                "email": email.trim(),
                "displayName": name,
                "restaurantName": restaurantName,
                "address": address,
                "videoId": null,
                "userId": null,
                "isRestaurant": isRestaurant
            })
            .then(() => {
                setRegistering(false)
                setRegistered(true);
            })
            .catch(error => {
                setRegistering(false);
                setLoginError(error.message);
            })

    }

    function checkEmail(email: string) {
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return reg.test(email);
    }

    function signInWithGoogle() {
        alert('Tuto metodu přihlášení zatím nepodporujeme');
        // var provider = new firebase.auth.GoogleAuthProvider();
        // firebase.auth()
        //     .signInWithPopup(provider)
        //     .then(() => {
        //         console.log("logged in");
        //     })
    }

    function gastroCss() {
        return isRestaurant ? "gastro" : ""
    }

    return <span className="login-one">
        <div className="login-one-box">
            <div className="container">
                <div className="login-one-box-logo">
                    <img className="img-fluid login-one-logo" src={isRestaurant ? "/img/gastro/logo.svg" : "/img/ratingo_logo_v1.png"} alt="" />
                </div>
                <div className="login-one-box-container">
                    <form action="#">
                    {register && <>
                        <div className="two-box-container-logo"><img src={isRestaurant ? "/img/gastro/Mail_v2.svg" : "/img/2.png"} alt="" /></div>
                        <h3 className="two-box-container-heading">Vyzkoušejte Ratingo<br /> zdarma a bez závazků</h3>
                        {!registered && !registering &&
                        <div className="alert alert-danger" role="alert" style={loginError === "" ? {visibility: 'hidden'} : {}}>
                            {loginError}
                        </div>
                        }
                        {registering &&
                        <div className="alert alert-info" role="alert">
                            Probíhá registrace
                        </div>
                        }
                        {registered ?
                            <div className="alert alert-info">
                                Vaše přihlašovací jméno a heslo jsme poslali emailem.
                            </div>
                            :
                            <div className="two-box-input-cover">
                            <input type="text" placeholder="Váš e-mail" name="email" className={"form-control two-box-input " + gastroCss()} disabled={registering} value={email} onChange={e => {setEmail(e.target.value)}} />
                            <input type="text" placeholder="Vaše jméno a příjmení" name="name" className={"form-control two-box-input " + gastroCss()} disabled={registering} value={name} onChange={e => {setName(e.target.value)}} />
                            {isRestaurant &&
                                <>
                                    <input type="text" placeholder="Název restaurace" name="restarantName" className={"form-control two-box-input " + gastroCss()} disabled={registering} value={restaurantName} onChange={e => {setRestaurantName(e.target.value)}} />
                                    <textarea className="form-control gastro" rows={6} name="reward" placeholder="Adresa" value={address} onChange={e => setAddress(e.target.value)} />
                                </>
                            }

                            <img src={isRestaurant ? "/img/gastro/Mail.svg" : "/img/Icons-Mail2.png"} alt="" />
                            </div>
                        }

                        <div className="two-box-input-btns">
                            {registered ? <>
                                    <a href="#" className={"two-login-btn " + gastroCss()} onClick={e => {e.preventDefault() ; setRegister(false); setRegistered(false);}}>Zpět na přihlášení</a>
                            </>
                                : <>
                                    <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setRegister(false); setEmailEntered(false);}}>Zpět</a>
                                    <a href="#" className={"two-login-btn " + gastroCss()} onClick={e => {e.preventDefault() ; createAccount();}}>Registrovat</a>
                                </>
                            }

                        </div>
                    </> }
                    { emailLogin && <>
                        <div className="two-box-container-logo"><img src={isRestaurant ? "/img/gastro/Mail_v2.svg" : "/img/2.png"} alt="" /></div>
                        <h3 className="two-box-container-heading">Přihlásit se</h3>
                        <div className="alert alert-danger" role="alert" style={ loginError === "" ?  {visibility: 'hidden'} : {}}>
                            {loginError}
                        </div>
                        <div className="two-box-input-cover">
                            {emailEntered ? <>
                                <input type="hidden" name="email" value={email} />
                                <input type="password" placeholder="Vaše heslo" name="password" className={"form-control two-box-input " + gastroCss()} value={password} onChange={e => {setPassword(e.target.value)}} />
                                <img src={isRestaurant ? "/img/gastro/Mail.svg" : "/img/Icons-Mail2.png"} alt="" />
                            </> : <>
                                <input type="text" placeholder="Váš e-mail" name="email" className={"form-control two-box-input " + gastroCss()} value={email} onChange={e => {setEmail(e.target.value)}} />
                                <img src={isRestaurant ? "/img/gastro/Mail.svg" : "/img/Icons-Mail2.png"} alt="" />
                            </>}

                        </div>
                        <div className="two-box-input-btns">
                            {emailEntered ?
                                <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setLoginError(""); setEmailEntered(false);}}>Zpět</a>
                            :
                                <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setLoginError(""); setEmailLogin(false);}}>Zrušit</a>
                            }
                            <a href="#" className={"two-login-btn " + gastroCss()} onClick={e => {e.preventDefault() ; loginClicked();}}>Přihlásit se</a>
                        </div>
                    </> }
                    { !register && !emailLogin && <>
                        <div className="one-box-container-logo"><img src={isRestaurant ? "/img/gastro/User.svg" : "img/1.png"} alt="" /></div>
                        {window.navigator.userAgent.includes("RatingGastroApp") ?
                            <>
                                <h3 className="one-box-container-heading">Přihlašte se prosím</h3>
                                <a href="#" className={"one-box-email-btn " + gastroCss()} onClick={e => {
                                    e.preventDefault();
                                    setEmailLogin(true);
                                }}>

                                    <span className="one-email-txt">Přihlásit se</span>
                                </a>
                            </>
                            :
                            <>
                                <h3 className="one-box-container-heading">Vytvořte si účet<br/> nebo se přihlašte</h3>
                                <a href="#" onClick={e => {
                                    e.preventDefault();
                                    setRegister(true)
                                }} className={"one-box-create-account-btn " + gastroCss()}>

                                    <span className="one-google-txt">Vytvořit účet</span>
                                </a>
                                <a href="#" className={"one-box-email-btn " + gastroCss()} onClick={e => {
                                    e.preventDefault();
                                    setEmailLogin(true);
                                }}>

                                    <span className="one-email-txt">Přihlásit se</span>
                                </a>
                            </>
                        }
                    </>
                    }
                    </form>
                </div>
            </div>
        </div>
    </span>
}

export default Login;
