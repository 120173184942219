import firebase from "firebase/app";
import React, {useEffect, useState, useContext} from "react";
import 'firebase/storage';
import {UserContext} from "../context";

function sortedFeedbackArraysEqual(array1: Feedback[], array2: Feedback[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (!array1[i].equals(array2[i])) {
            return false;
        }
    }

    return true;
}

interface FeedbackType {
    id: string
    date: Date
    email: string
}

interface Month {
    year: number,
    month: number
}

class Feedback {
    public feedback: FeedbackType;

    constructor(feedback: FeedbackType) {
        this.feedback = feedback;
    }

    equals(o: Feedback): boolean {
        return this.feedback.id === o.feedback.id && this.feedback.date.getTime() === o.feedback.date.getTime() &&
            this.feedback.email === o.feedback.email;
    }
}

function RestaurantEmails() {

    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    const [firstDate, setFirstDate] = useState<Date>();
    const [lastDate, setLastDate] = useState<Date>();
    const [selectedMonth, setSelectedMonth] = useState<Month>({
        year: new Date().getFullYear(),
        month: new Date().getMonth()
    });

    const {effectiveUser} = useContext(UserContext);

    useEffect(() => {
        let feedbackCollection = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        let feedbackCollection1 = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        let feedbackCollection2 = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        feedbackCollection.orderBy("date", "desc").limit(1).onSnapshot( snapshot => {
            snapshot.forEach(feedback => {
                let data = feedback.data() || {};

                let newDate = new Date(data.date.seconds * 1000);
                if (newDate.getTime() !== lastDate?.getTime()) {
                    setLastDate(newDate);
                    setSelectedMonth({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth()
                    })
                }
            });
        });

        feedbackCollection1.orderBy("date", "asc").limit(1).onSnapshot( snapshot => {
            snapshot.forEach(feedback => {
                let data = feedback.data() || {};

                let newDate = new Date(data.date.seconds * 1000);
                if (newDate.getTime() !== firstDate?.getTime()) {
                    setFirstDate(newDate);
                }
            });
        });

        feedbackCollection2.orderBy("date", "desc")
            .where("date", ">=", new Date(selectedMonth.year, selectedMonth.month, 1))
            .where("date", "<", new Date(getNextMonth(selectedMonth).year, getNextMonth(selectedMonth).month, 1))
            .onSnapshot(snapshot => {
                const allFeedbacks: Feedback[] = [];
                var knownEmails: string[] = [];

                snapshot.forEach( feedback => {
                    let data = feedback.data() || {};

                    if (data.email !== null && data.email !== "") {
                        if (!knownEmails.includes(data.email)) {
                            knownEmails.push(data.email);
                            allFeedbacks.push(new Feedback({
                                id: feedback.id,
                                date: new Date(data.date.seconds * 1000),
                                email: data.email
                            }));
                        }

                    }
                });

                if (!sortedFeedbackArraysEqual(allFeedbacks, feedbacks)) {
                    setFeedbacks(allFeedbacks);
                }
            });
    });

    function getMonths(): {value: string, description: string}[] {
        let months = [ "Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
            "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

        if (!firstDate || !lastDate) {
            return [];
        }

        var year = firstDate?.getFullYear() || new Date().getFullYear();
        var month = firstDate ? firstDate.getMonth() : new Date().getMonth();

        let endYear = lastDate?.getFullYear() || new Date().getFullYear();
        let endMonth = lastDate ? lastDate.getMonth() : new Date().getMonth();

        var result: {value: string, description: string}[] = [];

        while (year < endYear || month <= endMonth) {
            result.push({
                value: year + "-" + month,
                description: months[month] + " " + year
            })

            let next = getNextMonth({year: year, month: month});
            year = next.year;
            month = next.month;
        }

        return result;
    }

    function getNextMonth(month: Month): Month {
        if (month.month < 11) {
            return {
                year: month.year,
                month: month.month + 1
            }
        }

        return {
            year: month.year + 1,
            month: 0
        }
    }

    function updateSelectedMonth(value: string) {
        const [year, month] = value.split("-");

        setSelectedMonth({
            year: parseInt(year),
            month: parseInt(month)
        });
    }

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Emaily</h3>
            <div>
                <select id="monthSelect" className="form-control month-selection" value={selectedMonth.year + "-" + selectedMonth.month} onChange={event => {updateSelectedMonth(event.target.value)}}>
                    {getMonths().map(month =>
                        <option value={month.value}>{month.description}</option>
                    )}
                </select>
            </div>
        </div>

        {feedbacks.length === 0 ?
            <div className="row justify-content-center mt-4">
                <h1>Zatím nemáte žádnou zpětnou vazbu.</h1>
            </div>
            :
            <div id="all-videos" className="row mt-4">
                {feedbacks.map((feedback) =>
                    <div className="col-4">
                        <div className="video-box" style={{marginBottom: '20px'}}>
                            <div className="d-none d-md-block">
                                {feedback.feedback.email}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    </>
}

export default RestaurantEmails;