import './sass/app.sass'
import React, {useEffect} from 'react'

import {Link, Redirect} from "react-router-dom"

function Home(props) {

    useEffect(() => {
        document.body.style.backgroundColor = "transparent";
    });

    return (
        <div className="clip-container">
            <div>

                    <img className="logo" src={props.logoUrl} />
                    <br />
                    <p>Dejte nám neveřejnou a anonymní zpětnou vazbu a vyhrajte:</p>
                    <p>{props.reward}</p>
                    <ul>
                        <li>Vítěze vždy jednou měsíčně losuje náš systém.</li>
                        <li>Vedení restaurace se zavazuje, že si vaši zprávu vezme k srdci a udělá vše proto, abyste se rádi vraceli.</li>

                        {(() => {
                            if (props.questions.length < 5) {
                                return (
                                    <li>Stačí zodpovědět {props.questions.length} krátké otázky.</li>
                                )
                            } else   {
                                return (
                                    <li>Stačí zodpovědět {props.questions.length} krátkých otázek.</li>
                                )
                            }
                        })()}

                    </ul>

                <Link to="/Clip/otazka/1"  className="btn"  >Dát zpětnou vazbu</Link>

            </div>
        </div>
    );
}

export default Home
