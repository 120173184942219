

import React, {useEffect} from 'react'


export default function End() {

    useEffect(() => {
        document.body.style.backgroundColor = "transparent";
    });

    return (
        <div className="clip-container">
            <div>
                <div className='text-center'>
                    <p><span className='icon-thanks'></span></p>
                    <h1 className='big'>To je vše</h1>
                    <p>Přejeme vám hodně štěstí a pokud se zadaří, tak i dobrou chuť!</p>
                    <p>Můžete zavřít aplikaci. Aplikace z telefonu automaticky zmizí.</p>
                </div>
            </div>
        </div>
    );
}

