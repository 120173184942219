import {FormEvent, useContext, useEffect, useState} from "react";
import firebase from "firebase";
import {UserContext} from "../context";
import {deleteRestaurantAccount, setUserDisabled} from "../../functions/src";
// import * as admin from "firebase-admin";

interface User {
    uid: string
    email: string
    disabled: boolean
    displayName: string
    imgURL: string | null
}

interface IUidToFile {
    [key: string]: File;
}

function DefineLogo() {

    const {effectiveUser, setEffectiveUser} = useContext(UserContext);

    const [users, setUsers] = useState<User[]>([]);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [uidToFile, setUidToFile] = useState<IUidToFile>({});

    const storageRef = firebase.storage().ref();

    const changeHandler = (event: any, uid: string) => {
        var newUidToFile: IUidToFile = Object.assign({}, uidToFile);
        newUidToFile[uid] = event.target.files[0];

        setUidToFile(newUidToFile);
    };

    const deleteAccount = (uid: string) => {
        let password = prompt("Heslo pro smazani");

        if (password) {
            const deleteRestaurantAccount = firebase.app().functions('us-central1').httpsCallable("deleteRestaurantAccount");

            if (!uid || uid.length < 5) {
                alert("nastala chyba");
                return ;
            }

            deleteRestaurantAccount({uid: uid, password: password}).then(result => {
                if (result.data === "unauthorized") {
                    alert("chybné heslo");
                    return ;
                }

                if (result.data !== "success") {
                    alert("nastala chyba");
                    return ;
                }

                firebase.firestore().collection("RestaurantData").doc(uid).delete().then(() => {
                    storageRef.child("RestaurantLogo/" + uid + ".png").delete().then(() => {
                        alert("účet smazán");
                    });
                });
            });
        }
    }

    const setAccountDisabled = (uid: string, disabled: boolean) => {
        const setUserDisabled = firebase.app().functions('us-central1').httpsCallable("setUserDisabled");

        setUserDisabled( { uid: uid, disabled: disabled}).then(() => {
            alert("hotovo");
            setUsers(users); // this doesn't quite work
        });
    }

    const handleSubmission = (uid: string) => {
        uidToFile[uid]?.arrayBuffer().then(buffer => {
            storageRef.child("RestaurantLogo/" + uid + ".png").put(buffer).then(() => {
                alert("logo nahráno");
                setUsers(users);
            });
        })
    };

    useEffect(() => {
        let restaurants = firebase.firestore().collection("RestaurantData");
        restaurants.onSnapshot(snapshot => {
            // console.log("restaurant data collection");
            // console.log(snapshot);
            const promises:Promise<User>[] = [];

            snapshot.forEach( user => {
                const userEmailById = firebase.app().functions('us-central1').httpsCallable("userEmailById");

                const promise = userEmailById({ uid: user.id}).then( userData => {
                    // console.log("here");
                    // console.log(userData.data.id);
                    return storageRef.child("RestaurantLogo").child( userData.data.id + ".png").getDownloadURL().then( downloadURL => {
                        return {
                            uid: userData.data.id,
                            email: userData.data.email,
                            displayName: userData.data.displayName,
                            disabled: userData.data.disabled,
                            imgURL: downloadURL
                        }
                    }, rejectReason => {
                        return {
                            uid: userData.data.id,
                            email: userData.data.email,
                            displayName: userData.data.displayName,
                            disabled: userData.data.disabled,
                            imgURL: null
                        }
                    });

                    }
                );

                promises.push(promise);
            });

            Promise.all(promises).then((values: User[]) => {
                // values.sort()
                if (users.length !== values.length) { // data inside the collection will not change
                    setUsers(values);
                }
            });
        });
    });

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Admin</h3>
        </div>
        <div className="input-container">
            <button onClick={() => setEffectiveUser(firebase.auth().currentUser)}>Přihlásit jako já</button>

            <table>
            { users.map(user =>
                <tr>
                    <td>{user.imgURL ? <img src={user.imgURL} alt="" style={{maxWidth: '150px'}} /> : "obrázek nenahrán"}</td>
                    <td>{user.disabled ? "vypnutý" : "aktivní"}</td>
                    <td>{user.displayName}<br />{user.email}</td>
                    <td><input type="file" name="file" onChange={e => changeHandler(e, user.uid)} /></td>
                    <td><button onClick={() => handleSubmission(user.uid)}>Nahrát</button></td>
                    <td><button onClick={() => setEffectiveUser(user)}>Přihlásit</button></td>
                    <td><button onClick={() => deleteAccount(user.uid)}>Smazat účet</button></td>
                    <td><button onClick={() => setAccountDisabled(user.uid, !user.disabled)}>{user.disabled ? "Obnovit účet" : "Vypnout účet"}</button></td>
                </tr>

                )
            }
            </table>
        </div>
    </>
}

export default DefineLogo;