import firebase from "firebase/app";
import React, {useEffect, useState, MouseEvent, useContext} from "react";
import 'firebase/storage';
import {UserContext} from "../context";

function sortedFeedbackArraysEqual(array1: Feedback[], array2: Feedback[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0; i < array1.length; i++) {
        if (!array1[i].equals(array2[i])) {
            return false;
        }
    }

    return true;
}

interface Answer {
    question: string
    answer: string
    trimText: boolean
}

interface FeedbackType {
    id: string
    date: Date,
    email: string,
    answers: Answer[]
}

interface Month {
    year: number,
    month: number
}

class Feedback {
    public feedback: FeedbackType;

    constructor(feedback: FeedbackType) {
        this.feedback = feedback;
    }

    equals(o: Feedback): boolean {
        return this.feedback.id === o.feedback.id && this.feedback.date.getTime() === o.feedback.date.getTime() &&
            this.answersEqual(this.feedback.answers, o.feedback.answers);
    }

    answersEqual(a1: {question: string, answer: string}[], a2: {question: string, answer: string}[]): boolean {
        if (a1.length !== a2.length) {
            return false;
        }

        for (var i = 0; i < a1.length; i++) {
            if (a1[i].question !== a2[i].question || a1[i].answer !== a2[i].answer) {
                return false;
            }
        }

        return true;
    }
}

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function RestaurantFeedbacks() {

    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    const [firstDate, setFirstDate] = useState<Date>();
    const [lastDate, setLastDate] = useState<Date>();
    const [selectedMonth, setSelectedMonth] = useState<Month>({
        year: new Date().getFullYear(),
        month: new Date().getMonth()
    });
    const forceUpdate = useForceUpdate();

    const {effectiveUser} = useContext(UserContext);

    useEffect(() => {
        let feedbackCollection = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        let feedbackCollection1 = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        let feedbackCollection2 = firebase.firestore().collection("RestaurantData").doc(effectiveUser.uid)
            .collection("Feedbacks");

        feedbackCollection.orderBy("date", "desc").limit(1).onSnapshot( snapshot => {
            snapshot.forEach(feedback => {
                let data = feedback.data() || {};

                let newDate = new Date(data.date.seconds * 1000);
                if (newDate.getTime() !== lastDate?.getTime()) {
                    setLastDate(newDate);
                    setSelectedMonth({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth()
                    })
                }
            });
        });

        feedbackCollection1.orderBy("date", "asc").limit(1).onSnapshot( snapshot => {
            snapshot.forEach(feedback => {
                let data = feedback.data() || {};

                let newDate = new Date(data.date.seconds * 1000);
                if (newDate.getTime() !== firstDate?.getTime()) {
                    setFirstDate(newDate);
                }
            });
        });

        feedbackCollection2.orderBy("date", "desc")
            .where("date", ">=", new Date(selectedMonth.year, selectedMonth.month, 1))
            .where("date", "<", new Date(getNextMonth(selectedMonth).year, getNextMonth(selectedMonth).month, 1))
            .onSnapshot(snapshot => {
            const allFeedbacks: Feedback[] = [];

            snapshot.forEach( feedback => {
                let data = feedback.data() || {};

                allFeedbacks.push(new Feedback({
                    id: feedback.id,
                    date: new Date(data.date.seconds * 1000),
                    email: data.email,
                    answers: data.responses.filter((a: any) => a).map((a: any) => {
                        return {
                            question: a.question,
                            answer: a.answer,
                            trimText: a.answer && a.answer.length > 100
                        }
                        })
                }));
            });

            if (!sortedFeedbackArraysEqual(allFeedbacks, feedbacks)) {
                setFeedbacks(allFeedbacks);
            }
        });
    });

    function getMonths(): {value: string, description: string}[] {
        let months = [ "Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
            "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

        if (!firstDate || !lastDate) {
            return [];
        }

        var year = firstDate?.getFullYear() || new Date().getFullYear();
        var month = firstDate ? firstDate.getMonth() : new Date().getMonth();

        let endYear = lastDate?.getFullYear() || new Date().getFullYear();
        let endMonth = lastDate ? lastDate.getMonth() : new Date().getMonth();

        var result: {value: string, description: string}[] = [];

        while (year < endYear || month <= endMonth) {
            result.push({
                value: year + "-" + month,
                description: months[month] + " " + year
            })

            let next = getNextMonth({year: year, month: month});
            year = next.year;
            month = next.month;
        }

        return result;
    }

    function getNextMonth(month: Month): Month {
        if (month.month < 11) {
            return {
                year: month.year,
                month: month.month + 1
            }
        }

        return {
            year: month.year + 1,
            month: 0
        }
    }

    function deleteFeedback(id: string) {
        if (window.confirm('Skutečně chcete zpětnou vazbu smazat?')) {

            firebase.firestore().collection("RestaurantData")
                .doc(firebase.auth().currentUser?.uid)
                .collection("Feedbacks")
                .doc(id)
                .delete();
        }
    }

    function toggleTrimText(e: MouseEvent<HTMLAnchorElement>, answer: Answer) {
        e.preventDefault();
        answer.trimText = !answer.trimText;
        forceUpdate();
    }

    function updateSelectedMonth(value: string) {
        const [year, month] = value.split("-");

        console.log("update selected month");
        setSelectedMonth({
            year: parseInt(year),
            month: parseInt(month)
        });
    }

    return <>
        <div className="content-right-upper">
            <h3 className="content-right-upper-heading">Odpovědi</h3>
            <div>
                <select id="monthSelect" className="form-control month-selection" value={selectedMonth.year + "-" + selectedMonth.month} onChange={event => {updateSelectedMonth(event.target.value)}}>
                    {getMonths().map(month =>
                        <option value={month.value}>{month.description}</option>
                    )}
                </select>
            </div>
        </div>

        {feedbacks.length === 0 ?
            <div className="row justify-content-center mt-4">
                <h1>Zatím nemáte žádnou zpětnou vazbu.</h1>
            </div>
            :
            <div id="all-videos" className="row mt-4">
                {feedbacks.map((feedback) =>
                    <div className="col-12">
                        <div className="video-box" style={{marginBottom: '20px'}}>
                            <div className="d-flex justify-content-between">
                                <div className="video-date">{Intl.DateTimeFormat('cs-CZ').format(feedback.feedback.date)}
                                    { ( firebase.auth().currentUser?.email === 'tonda.karasek@gmail.com' || firebase.auth().currentUser?.email === 'petra@smartonline.cz' ) &&
                                        <>; <strong>{feedback.feedback.email}</strong></>
                                    }
                                </div>
                                <div className="feedback-delete">
                                    <a href="#" onClick={event => {
                                        event.preventDefault();
                                        deleteFeedback(feedback.feedback.id)
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <defs>
                                                <style>{`.a{fill:none;}.b{fill:#a6b3bf;}`}</style>
                                            </defs>
                                            <rect className="a" width="24" height="24" transform="translate(0 0)"/>
                                            <path className="b"
                                                  d="M5.21,19.507a3.016,3.016,0,0,1-2.993-2.77L1.306,4.876H.75a.75.75,0,0,1-.1-1.493l.1-.006H4.064v-1.5A1.878,1.878,0,0,1,5.94,0h5.627a1.878,1.878,0,0,1,1.876,1.876v1.5h3.315a.75.75,0,0,1,.1,1.493l-.1.006H16.2l-.912,11.861a3.015,3.015,0,0,1-2.993,2.77Zm-1.5-2.885A1.5,1.5,0,0,0,5.073,18l.137.006H12.3a1.508,1.508,0,0,0,1.5-1.386l.9-11.746H2.809ZM5.565,1.876v1.5h6.379v-1.5a.377.377,0,0,0-.3-.368L11.567,1.5H5.94A.376.376,0,0,0,5.565,1.876ZM6.911,13.314a.75.75,0,0,1-.127-.971l.068-.087.843-.942-.942-.843a.75.75,0,0,1,.912-1.185l.088.068.942.842.842-.941a.75.75,0,0,1,1.186.912l-.068.088-.843.942.942.842a.75.75,0,0,1-.913,1.186l-.087-.068-.942-.843-.843.942a.749.749,0,0,1-1.058.059Z"
                                                  transform="translate(3.252 2.251)"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                            <div className="d-none d-md-block">
                                <table className="feedback-table">
                                    {feedback.feedback.answers.map( (answer, index) =>
                                        <tr>
                                            <td style={{width: '25%', paddingRight: '30px', paddingBottom: '30px'}}>
                                                <strong>{answer.question}</strong>
                                            </td>
                                            <td>
                                                {answer.trimText ? answer.answer.substring(0, 100) + "..." : answer.answer}
                                                {answer.answer.length > 100 &&
                                                    <>
                                                        <br />
                                                        <a className="feedback-more" href="#" onClick={(e) => {toggleTrimText(e, answer)}}>{answer.trimText ? "Více...": "Méně..."}</a>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </table>
                            </div>

                            <div className="d-md-none">
                                {feedback.feedback.answers.map( (answer, index) =>
                                    <>
                                        <div>
                                            <strong>{answer.question}</strong>
                                        </div>
                                        <div>
                                            {answer.trimText ? answer.answer.substring(0, 100) + "..." : answer.answer}
                                            {answer.answer.length > 100 &&
                                                <>
                                                    <br />
                                                    <a className="feedback-more" href="#" onClick={(e) => {toggleTrimText(e, answer)}}>{answer.trimText ? "Více...": "Méně..."}</a>
                                                </>
                                            }
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    </>
}

export default RestaurantFeedbacks;