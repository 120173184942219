

import { useNavigate } from "react-router-dom"

import React, {useEffect, useState} from 'react'


function Thanks(props) {

    useEffect(() => {
        document.body.style.backgroundColor = "transparent";
    });

    const navigate = useNavigate();

    const [checked, setChecked] = useState(true);
    const [emailClass, setEmailClass] = useState('mail');

    const [alertMail, setAlertMail] = useState(false);

    function sendData() {

        if(!checked) {
            props.sendResponse(() => {
                navigate('/Clip/konec')
            });
        }  else if (checked && validateEmail(props.email)){
            props.setAgreeMarketing(checked)
            props.sendResponse(() => {
                navigate('/Clip/konec')
            });
        } else {
            setAlertMail(true)
        }
    }

    function validMail(value) {

        if(checked && validateEmail(value)) {
            setEmailClass('mail yellow')
            props.setEmail(value)
            setAlertMail(false)
        } else {
            setEmailClass('mail')
            props.setEmail(false)
        }

        if(validateEmail(value)) {
            props.setEmail(value)
        } else {
            props.setEmail(false)
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <div className="clip-container">
            <div>
                <div className='text-center'>
                    <p><span className='icon-thanks'></span></p>
                    <h1>Děkujeme</h1>
                    <p className='blue'>jméno restauratéra: {props.settings.ownerName} </p>
                    <p>Vložte prosím svůj e-mail, ať vás můžeme kontaktovat v případě výhry. Pro vedení podniku zůstane váš e-mail skrytý.</p>
                </div>
                <input className={emailClass} type="mail" onChange={(e) => validMail(e.target.value)}/>

                {(() => {
                    if (alertMail) {
                        return ( <p className='alert'><b>Email není validní!</b></p> )
                    }
                })()}

                <p>Váš e-mail nebude spárovaný s vaší odpovědí.</p>

                <label className='checkbox'>
                    <input  type="checkbox" checked={checked}  onChange={() => setChecked(!checked)} />
                    Chci se zařadit do slosování
                </label>

                <button  className="btn" onClick={() => sendData()}  >Odeslat zpětnou vazbu</button>
            </div>
        </div>
    );
}

export default Thanks
