
import {Link, useParams } from "react-router-dom"
import React, {useEffect, useState} from 'react'

export default function Question(props) {

    let { questionId } = useParams()

    const [countWords, setCountWords]  = useState(0)
    const [questionIdNumber, setQuestionIdNumber]  = useState(0)
    const [textForm, setTextForm]  = useState('')

    function setResponse(value) {
        let response = props.response;
        response[questionIdNumber-1] = { 'question': props.questions[questionIdNumber-1].question, 'answer': value }
        props.setResponse(response)
        setTextForm(props.response[questionIdNumber-1].answer)
        setCountWords(props.response[questionIdNumber-1].answer.split(' ').length)
    }


    useEffect(() => {
        document.body.style.backgroundColor = "transparent";

        setQuestionIdNumber(Number(questionId))
        if(props.response[questionIdNumber-1])  {
            setTextForm(props.response[questionIdNumber-1].answer)
            setCountWords(props.response[questionIdNumber-1].answer.split(' ').length)
        } else {
            setCountWords(0)
            setTextForm('')
        }
    });


    return (
        <div className="clip-container">
            <div>
                {(() => {
                    if (questionIdNumber === 1) {
                        return (
                            <Link to={"/Clip/?userId="+props.userId}   className="arrow-back"  ></Link>
                        )
                    } else  {
                        return (
                            <Link to={'/Clip/otazka/'+(questionIdNumber-1)}  className="arrow-back"  ></Link>
                        )
                    }
                })()}
                <h1 className="questions"><span className="bubble"></span>Otázka { questionIdNumber }/{props.questions.length}</h1>
                <h2>{props.questions[questionId-1].question}</h2>
                <textarea cols="30" rows="6" value={textForm}   name={'question'+questionId} onChange={(e) => setResponse(e.target.value)}></textarea>
                <p className="counter">{countWords}/500 slov</p>
                {(() => {
                    if (questionIdNumber < props.questions.length) {
                        return (
                            <Link to={'/Clip/otazka/'+(questionIdNumber+1)}   className="btn right"  >Další otázka</Link>
                        )
                    } else   {
                        return (
                            <Link to="/Clip/dekujeme"  className="btn right"  >Pokračovat</Link>
                        )
                    }
                })()}
            </div>
        </div>
    );
}
